import React from 'react'
import logo from '../img/logo.png';
import { Link } from 'react-router-dom';
import ContactUs from '../components/contactus'
import Header2 from '../components/header2';
import Footer from '../components/footer';
const contact = () => {
  return (
    <>
    
<Header2/>
    <ContactUs />
    <Footer />
    </>
      )
}

export default contact
