// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
    font-family: "poppins", sans-serif;
}

.containerbody {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffff;
}

.otp-container {
    background-color: #fff;
    padding: 30px 65px;
    border-radius: 12px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
p{
  margin-top: 10px;  
  margin-bottom: 30px;
}
 h1{
    margin-top: 45px;
}
form input {
    height: 40px;
    width: 42px;
    margin: 2px;
    margin-left: 10px;
}

.p {
    margin-bottom: 15px;
    margin-top: 15px;
    align-items: center;
    padding: 10px;

}
a{
    margin-left: 50px;
    margin-top: 4px;
    color: #4070f4;
}
.p-h{
    margin-left: 12px;
}
button {
    display: block;
    margin: 0 auto;
    padding: 10px;
    width: 250px;
    background-color:magenta;
color: white;
    margin-bottom: 45px;
    border:1px solid #fff ;
    border-radius: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/otp.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;IACX,sBAAsB;IACtB,kCAAkC;AACtC;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,uBAAuB;AAC3B;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,yCAAyC;AAC7C;AACA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;CACC;IACG,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,WAAW;IACX,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;;AAEjB;AACA;IACI,iBAAiB;IACjB,eAAe;IACf,cAAc;AAClB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,cAAc;IACd,cAAc;IACd,aAAa;IACb,YAAY;IACZ,wBAAwB;AAC5B,YAAY;IACR,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;AACtB","sourcesContent":["* {\r\n    margin: 0%;\r\n    padding: 0%;\r\n    box-sizing: border-box;\r\n    font-family: \"poppins\", sans-serif;\r\n}\r\n\r\n.containerbody {\r\n    min-height: 100vh;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    background-color: #ffff;\r\n}\r\n\r\n.otp-container {\r\n    background-color: #fff;\r\n    padding: 30px 65px;\r\n    border-radius: 12px;\r\n    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);\r\n}\r\np{\r\n  margin-top: 10px;  \r\n  margin-bottom: 30px;\r\n}\r\n h1{\r\n    margin-top: 45px;\r\n}\r\nform input {\r\n    height: 40px;\r\n    width: 42px;\r\n    margin: 2px;\r\n    margin-left: 10px;\r\n}\r\n\r\n.p {\r\n    margin-bottom: 15px;\r\n    margin-top: 15px;\r\n    align-items: center;\r\n    padding: 10px;\r\n\r\n}\r\na{\r\n    margin-left: 50px;\r\n    margin-top: 4px;\r\n    color: #4070f4;\r\n}\r\n.p-h{\r\n    margin-left: 12px;\r\n}\r\nbutton {\r\n    display: block;\r\n    margin: 0 auto;\r\n    padding: 10px;\r\n    width: 250px;\r\n    background-color:magenta;\r\ncolor: white;\r\n    margin-bottom: 45px;\r\n    border:1px solid #fff ;\r\n    border-radius: 3px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
